<template>
    <div class="relative width_100_percent height_100_percent">
        <Card class="mt_15" :title="info.title" v-for="info in list" :key="info.id">
            <div class="relative flex flex_wrap align_start">
                <div class="form_item" v-for="subInfo in info.list" :key="subInfo.id">
                    <template v-if="subInfo.type === 'upload'">
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200"><previewImage :thumWidth="60" :thumHeight="60" :src="subInfo.value"></previewImage></div>
                    </template>
                    <template v-else-if="subInfo.type === 'uploadMultiple' || subInfo.type=== true ">
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200 flex flex_wrap align_center">
                            <previewImage v-for="(src, idx) in !!subInfo.value ? subInfo.value.split(',') : []" :class="{ ml_10: idx > 0 }" :key="encodeURIComponent(src)" :thumWidth="60" :thumHeight="60" :src="src"></previewImage>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200 break_all">{{ subInfo.value }}</div>
                    </template>
                </div>
            </div>
        </Card>
        <div style="margin-top: 15px;">是否自愿加入小太阳计划，领取琪朗ALED全光谱体验灯具试用7天<span v-if="is_agree == '1'" style="margin-left: 20px;color: blue;">自愿加入</span><span v-else style="margin-left: 20px">--</span></div>
        <Card class="mt_20" title="检测人员信息">
            <div class="form_item">
                <div class="form_label">测试员姓名</div>
                <!-- <Input class="width_300" v-model.trim="username" :maxlength="10" show-word-limit /> -->
                {{ accout_nickname }}
            </div>
            <div class="form_item">
                <div class="form_label">检测时间</div>
                <!-- <myDate v-model="jiancetime"></myDate> -->
                {{ ctime }}
            </div>
        </Card>
        <Card class="mt_20" title="更改记录">
            <div v-for="(item, index) in modify_logs" :key="index" style="border-bottom: solid #e8eaec 1px;margin-top: 20px;">
                <div class="form_item">
                    <div class="form_label">更改时间</div>
                    <!-- <Input class="width_300" v-model.trim="username" :maxlength="10" show-word-limit /> -->
                    {{ item.created_at }}
                </div>
                <div class="form_item">
                    <div class="form_label">更改人员</div>
                    <!-- <myDate v-model="jiancetime"></myDate> -->
                    {{ item.operator }}
                </div>
                <div class="form_item">
                    <div class="form_label">更改内容</div>
                    <!-- <myDate v-model="jiancetime"></myDate> -->
                    {{ item.content }}
                </div>
            </div>
        </Card>
        <div style="height: 100px"></div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onBack">返回</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import myDate from '@/components/myDate';
import { reqCheckView,geteditForm  } from '@/lib/request/auth';
import previewImage from '@/components/previewImage';
import { consoleJson } from '@/lib/util';

export default {
    name: 'monitorManagerBillDetail',
    components: {
        myDate,
        previewImage,
    },
    data() {
        return {
            id: null,
            list: [],
            username: null,
            jiancetime: null,
            accout_nickname: null,
            ctime: null,
            zindex:0,
            zid:'',
            modify_logs:[],
            is_agree:0
        };
    },
    mounted() {
        this.id = this.$route.query.id;
        this.zid = this.$route.query.zid;
        this.routeIdInject();
        this.getDetail();
        this.getHistory();
    },
    methods: {
        getHistory(){
            geteditForm({ re_id: this.id }).then(res => {
                res.data.forEach((item,index)=>{
                    if(this.zid == item.id){
                        this.zindex = index;
                    }
                })
                this.check_note = res.data[this.zindex].check_note;
                this.modify_logs = res.data[this.zindex].modify_logs;
                this.ctime = new Date(+res.data[0].ctime * 1000).Format('yyyy-MM-dd HH:mm:ss');
                this.accout_nickname = res.data[0].accout_nickname;
                // this.ctime = res.data.ctime;
            });
        },
        //获取详情数据
        getDetail() {
            this.showAjaxLoading();
            reqCheckView({ id: this.zid })
                .then(res => {
                    //检测单详情
                    console.log('####################################### reqCheckView res=', res);
                    let { check_note, accout_nickname, ctime } = res?.data || {};
                    let note = JSON.parse(check_note || '[]');
                    let noteArr = note.map(info => info.list) || [];
                    noteArr = noteArr.reduce((prev, curr) => prev.concat(curr), []);
                    this.list = noteArr;
                    this.is_agree = res.data.is_agree;
                    this.accout_nickname = accout_nickname;
                    this.ctime = new Date(+ctime * 1000).Format('yyyy-MM-dd HH:mm:ss');

                    consoleJson(noteArr);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //返回
        onBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.form_item {
    &.bill {
        width: 400px;
    }
    .form_label {
        align-self: flex-start;
    }
}
</style>
